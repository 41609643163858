<template>
  <div class="IntroImage" v-if="show" @click="$emit('click');show=false;">
    <div class="">
      <img src="/images/introImg.png">
    </div>
  </div>
</template>
<script>
  export default {
    components: {
    },
    props: {
    },
    data() {
      return {
        show: true,
      }
    },
    inject: {
      publicData: {
        default: {}
      },
      getFunc: {}
    },
    computed: {
      autoTourPlayer() {
        return this.publicData.autoTourPlayer
      }
    },
    created() {
    },
    mounted() {
      this.$emit('ready', {
        vm: this,
        funcs: {
        },
        events: {
        }
      })
    },
    destroyed() {
    },
    methods: {
    },
  }
</script>
<style scoped>
  .IntroImage {
    width: 100%;
    height: 100%;
    pointer-events: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    .IntroImage > div {
      max-width: 40vw;
    }

  @media (min-width: 720px) {
    .IntroImage > div {
    }
  }
</style>
